<template>
  <div>
    <el-form ref="pushref" :model="pushform" label-width="130px">
      <el-form-item :label="$t('hq.qdts')" required>
        <el-switch
          v-model="pushform.push_isopend"
          active-value="1"
          inactive-value="0"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="APP ID">
        <el-input v-model="pushform.appid"></el-input>
      </el-form-item>
      <el-form-item label="APP KEY">
        <el-input v-model="pushform.appkey"></el-input>
      </el-form-item>
      <el-form-item label="Master Secret">
        <el-input v-model="pushform.secret"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="reset">{{ $t('chongzhi') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pushform: {
        push_isopend: '0',
        appid: '',
        appkey: '',
        secret: ''
      }
    }
  },
  created() {
    this.getpush()
  },
  methods: {
    async getpush() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.get('/admin/news/pushconfig')
      if (data) {
        if (data.code === 200) {
          this.pushform = data.data
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 提交
    async onSubmit() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.post(
        '/admin/news/pushconfig',
        this.pushform
      )
      if (data) {
        if (data.code === 200) {
          this.$message.success(this.$t('chenggong'))
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 重置
    reset() {
      this.getpush()
    }
  }
}
</script>
